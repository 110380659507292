

export default {
        position: 'fixed',
        top: 16,
        right: 16,
        cursor: 'pointer',
        transitionDuration: '0.2s',
        transitionTimingFunction: 'linear',
        transitionDelay: '0s',
        zIndex: 10,
        
        
}