import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../theme/styles';
import * as constants from '../../constants/constants';
import { CardHeader, Avatar, CardContent, CardActions, IconButton, Tooltip, Typography, Hidden, Badge, Divider, Box } from '@material-ui/core';
import FormatterUtil from '../../utils/FormatterUtil';
import UrlBuilderUtil from '../../utils/projectBased/UrlBuilderUtil';
import TranslatorUtil from '../../utils/TranslatorUtil';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PageviewIcon from '@material-ui/icons/Pageview';
import { withRouter } from "react-router-dom";
import DateUtil from '../../utils/DateUtil';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import GroupIcon from '@material-ui/icons/Group';
import AccessUtil from '../../utils/projectBased/AccessUtil';
import LockIcon from '@material-ui/icons/Lock';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import objectPath from 'object-path';
//import GroupIcon from '@material-ui/icons/Group';

class ProjectCardContent extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        expanded: false,
      }
    }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  };

  render () {
  const { classes, item, history, onDeleteClicked, onSelectClicked } = this.props;
  if(!item){
    return null;
  }

  const cardAvatar = <Avatar aria-label={item.name} className={classes.avatar} style={{backgroundColor:item.color}}>
  {FormatterUtil.getIconText(item.name)}
</Avatar>;
const isLocked = AccessUtil.isProjectLocked(item);
const lockScheduled = (!isLocked && objectPath.get(item, "lockDate", null) && objectPath.get(item, "lockTime", null));

  return (
    <div>
    <CardHeader
      onClick={() => onSelectClicked(item)}
        avatar={
          isLocked ?
          <Badge anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }} badgeContent={<Avatar className={"project-card-state-badge-avatar"+(isLocked ? " locked" : "")} style={{width: 24,height: 24}}>
            <LockIcon fontSize='small' /></Avatar>}>{cardAvatar}</Badge> : cardAvatar
        }
        titleTypographyProps={{variant:'h2' }}
        title={item.name}
        subheader={DateUtil.displayTimeFromNow(item.updatedTime, TranslatorUtil.getUserLangFromStore())}
      />
      <CardContent
      onClick={() => onSelectClicked(item)}
      >
        <Typography variant="body2" color="textSecondary" component="p">
        {item.description}
        </Typography>
        {lockScheduled && <Box pt={2}>
          <Divider />
          <Typography variant="caption">
            <small>{TranslatorUtil.t("Set to be locked")} : {DateUtil.displayDateTime(objectPath.get(item, "lockDate", null), objectPath.get(item, "lockTime", null))}</small>
          </Typography>
        </Box>}
      </CardContent>

      <CardActions className='project-card-actions'>

     
      
      <Hidden lgDown><Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Select project")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Select project")} onClick={() => onSelectClicked(item)}>
                                        <TrendingFlatIcon />
                                    </IconButton>
                                </Tooltip></Hidden>
                                
                                
                                {
                                  AccessUtil.isAtLeastModeratorForProject(item.id) ? 
                                  <React.Fragment>
                                    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Preview")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Preview")} onClick={() => history.push(UrlBuilderUtil.getProjectPreview(item.id))}>
                                        <PageviewIcon />
                                    </IconButton>
                                </Tooltip>
<Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Analytics")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Analytics")} onClick={() => history.push(UrlBuilderUtil.getProjectStats(item.id))}>
                                        <EqualizerIcon />
                                    </IconButton>
                                </Tooltip>
<Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Notify users")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Notify users")} onClick={() => history.push(UrlBuilderUtil.getProjectNotifyUsers(item.id))}>
                                        <NotificationsActiveIcon />
                                    </IconButton>
                                </Tooltip>

                              <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Users")}>
                                    <IconButton  aria-label={TranslatorUtil.t("Users")} onClick={() => history.push(UrlBuilderUtil.getProjectUsersEdit(item.id))}>
                                        <GroupIcon />
                                    </IconButton>
                                </Tooltip>
                                    <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Edit")}>
                                  <IconButton  aria-label={TranslatorUtil.t("Edit")} onClick={() => history.push(UrlBuilderUtil.getProjectEdit(item.id))}>
                                      <EditIcon />
                                  </IconButton>
                                 
                              </Tooltip> 
                              {onDeleteClicked ? <Tooltip enterDelay={constants.TOOLTIP_CARDS_DELAY} title={TranslatorUtil.t("Delete")}>
                                  <IconButton  aria-label={TranslatorUtil.t("Delete")} onClick={() => onDeleteClicked(item)}>
                                      <DeleteIcon color="error" />
                                  </IconButton>
                              </Tooltip> : null}
                                  </React.Fragment>: null
                                  
                                }
                                
     
                                
    </CardActions>
    </div>
  );
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(ProjectCardContent));