import React from "react";
import { withRouter } from "react-router-dom";

//import * as constants from '../../constants/constants';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import firestoreApi from "../../../firebase";
import FirestoreCollectionUtil from "../../../utils/FirestoreCollectionUtil";
import ComponentLoading from "../../../components/ComponentLoading";
import styles from "../../../theme/styles";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import DateUtil from "../../../utils/DateUtil";
import DoneIcon from '@material-ui/icons/Done';
import AccessUtil from "../../../utils/projectBased/AccessUtil";

class NotificationsLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        items: [],
        dataLoading: true
    }
  }




  componentDidMount() {
    if(AccessUtil.isCompanyAdmin(true)){
      firestoreApi.collection("notification").orderBy("createdTime", "desc").limit(200).get().then((response) => {
        
          this.setState({
            items: FirestoreCollectionUtil.parseCollectionData(response),
              dataLoading: false
          });
      });
    }
  }


  render() {
    const { dataLoading, items } = this.state;



    return <div>
      
      {dataLoading ? <ComponentLoading /> :
        
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{TranslatorUtil.t("Subject")}</TableCell>
                  <TableCell>{TranslatorUtil.t("To")}</TableCell>
                  <TableCell>{TranslatorUtil.t("Text")}</TableCell>
                  <TableCell>{TranslatorUtil.t("Created")}</TableCell>
                  <TableCell>{TranslatorUtil.t("Read")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {items.map((item, index) => {
                return <TableRow key={index}>
                <TableCell>{item.message.subject}</TableCell>
                <TableCell>{item.to}</TableCell>
                <TableCell>{item.message.text}</TableCell>
                <TableCell>{DateUtil.localizedTime(item.createdTime)}</TableCell>
                <TableCell>{item.read ? <DoneIcon /> : null}</TableCell>
              </TableRow>;
              })}
              </TableBody>
            </Table>
          </TableContainer>
  }
    </div>
  }
}




export default withRouter(withStyles(styles, { withTheme: true })(NotificationsLog));
