const messages = {
    "langCode": "cs",
    "Dashboard": "Nástěnka",
    "Password": "Heslo",
    "Name": "Jméno",
    "Surname": "Příjmení",
    "Admin": "Správce",
    "Email": "E-mail",
    "Login": "Přihlásit se",
    "Sign in": "Vytvořit účet",
    "Logout": "Odhlásit se",
    "Items": "Položky",
    "Password recovery link has been sent to your registered e-mail address.": "Odkaz pro obnovení hesla byl odeslán na váš zaregistrovaný e-mail.",
    "Email with password recovery link sent": "E-mail s odkazem pro obnovení hesla odeslán.",
    "You are logged in": "Jste přihlášen/a.",
    "No conversation started yet": "Zatím nebyla zahájena žádná konverzace",
    "No conversation started yet.": "Zatím nebyla zahájena žádná konverzace.",
    "Reset password": "Obnovit heslo",
    "Confirm password": "Zopakujte heslo.",
    "Passwords do not match": "Hesla se neshodují.",
    "Please wait": "Načítání",
    "Show more": "Zobrazit více",
    "SimpleItem": "Položka",
    "Simple Item": "Položka",
    "Simple Items": "Položky",
    "Please confirm you wish to remove ": "Potvrďte prosím odstranění ",
    "Are you sure?": "Jste si jistí?",
    "Cancel": "Zrušit",
    "No notifications found": "Nemáte žádné notifikace.",
    "New": "Nový",
    "New Discussion": "Nová diskuze",
    "Update Discussion": "Aktualizovat diskusi",
    "Update": "Aktualizovat",
    "Save": "Uložit",
    "Delete": "Odstranit",
    "Edit": "Upravit",
    "Projects": "Projekty",
    "Project": "Projekt",
    "Profile": "Profil",
    "Back": "Zpět",
    "back": "zpět",
    "Description": "Popis",
    "Profile description": "Kdo jsem aneb Jak byste se představili novému známému ve 3 větách?",
    "User profile": "Profil uživatele",
    "Open chat": "Napsat soukromou zprávu",
    "Open support": "Napsat soukromou zprávu mimo diskuze",
    "Comment": "Komentář",
    "Message": "Zpráva",
    "Created": "Vytvořeno",
    "No results": "Nic nenalezeno",
    "Select conversation please": "Vyberte prosím konverzaci.",
    "male": "muž",
    "female": "žena",
    "Required": "Povinné",
    "Uploading": "Nahrávám",
    "uploaded": "nahrán",
    "locked": "uzamčen",
    "unlocked": "odemčen",
    "Locked on project level!": "Zamčeno na úrovni projektu!",
    "Lock": "Uzamknout",
    "Unlock": "Odemknout",
    "discussion for all respondents manually": "diskusi pro všechny respondenty manuálně.",
    "project and its discussions for all respondents manually": "projekt a jeho diskuze pro všechny respondenty ručně.",
    "manually": "manuálně",
    "Error": "Chyba",
    "Unfortunately something went wrong and invitation has expired or is not valid anymore": "Bohužel se něco pokazilo a pozvání vypršelo nebo již není platné",
    "Unfortunately something went wrong and the invitation has expired or is not valid anymore.": "Bohužel se něco pokazilo a pozvání vypršelo nebo již není platné.",
    "Project unlocked": "Projekt odemknut",
    "Project locked": "Projekt zamknut",
    "Discussion has been unlocked": "Diskuze byla otevřena",
    "Discussion has been locked": "Diskuze byla uzavřena",
    "Age": "Věk",
    "Gender": "Pohlaví",
    "Phone": "Telefon",
    "Language": "Jazyk",
    "Turn off email notifications": "Vypněte e-mailová oznámení",
    "Not selected": "Nevybráno",
    "Discussions": "Diskuze",
    "Select project": "Vyberte projekt",
    "Users": "Uživatelé",
    "Add new item": "Přidat novou položku",
    "Confirm": "Potvrdit",
    "Start discussion": "Zahájit diskusi",
    "Add": "Přidat",
    "Select": "Vybrat",
    "By accepting this invitation you agree with terms and conditions above": "Přijetím této pozvánky souhlasíte s výše uvedenými podmínkami",
    "By accepting this invitation you agree with terms and conditions above.": "Přijetím této pozvánky souhlasíte s výše uvedenými podmínkami.",
    "Accept invitation": "Přijmout pozvánku",
    "Login first please": "Nejprve se, prosím, přihlaste.",
    "View ": "Zobrazit ",
    "company": "firma",
    "Company": "Firma",
    "Company admins": "Administrátorské účty firmy",
    "Chat": "Zprávy",
    "Reply": "Reagovat",
    "Please submit a message to see opinions of others": "Chcete-li zobrazit názory ostatních, odešlete alespoň jednu zprávu.",
    "Please submit a message to see opinions of others.": "Chcete-li zobrazit názory ostatních, odešlete alespoň jednu zprávu.",
    "Mark as read": "Označit jako přečtené",
    "Preview": "Náhled",
    "Notify users": "Informujte uživatele",
    "All project users": "Všechny uživatele projektu",
    "notification sent to": "oznámení odesláno",
    "notification sent to role": "oznámení odesláno roli",
    "notification sent to all project users": "oznámení odesláno všem uživatelům projektu",
    "added": "přidán",
    "Project has been added": "Projekt byl přidán",
    "Project has been updated": "Projekt byl aktualizován",
    "Profile has been added": "Profil byl přidán",
    "Profile has been updated": "Profil byl aktualizován",
    "Discussion has been added": "Diskuze byla přidána",
    "Discussion has been updated": "Diskuze byla aktualizována",
    "reply to message link": "odpovědět na zprávu",
    "You": "Vy",
    "Terms and conditions": "Pravidla a podmínky",
    "Chat about this message": "Diskutovat o této zprávě",
    "Comment added": "Komentář přidán.",
    "User tags updated": "Štítky uživatele aktualizovány.",
    "User not found": "Uživatel nenalezen.",
    "close": "zavřít",
    "Welcome back": "Vítejte zpět",
    "Please continue below if you forgot your password or do not have account": "Pokračujte níže, pokud jste zapomněli heslo nebo nemáte účet.",
    "Please continue below if you forgot your password or do not have an account yet.": "Pokračujte níže, pokud jste zapomněli heslo nebo nemáte účet.",
    "If you think you know the password go back to login below": "Pokud si myslíte, že znáte heslo, vraťte se zpět a přihlaste se",
    "If you think you know the password go back to the login below.": "Pokud si myslíte, že znáte heslo, vraťte se zpět a přihlaste se.",
    "If you already have an account go straight to login below": "Pokud již máte účet, přejděte rovnou k přihlášení níže.",
    "If you already have an account go straight to the login below.": "Pokud již máte účet, přejděte rovnou k přihlášení níže.",
    "Conversation with": "Konverzace s",
    "Start conversation with": "Zahájit konverzaci s",
    "To start conversation please select message you want to talk about": "Pro zahájení konverzace vyberte zprávu, o které chcete diskutovat.",
    "Message preview": "Náhled zprávy",
    "Youtube Embed Link": "Odkaz pro vložení na YouTube",
    "Color": "Barva",
    "Images": "Obrázky",
    "max 8MB per file": "maximálně 8 MB na soubor",
    "Welcome page content": "Obsah uvítací stránky",
    "Terms & Conditions": "Všeobecné podmínky",
    "Lock date": "Datum uzamknutí",
    "Lock time": "Čas uzamknutí",
    "Publish date": "Datum publikovaní",
    "Publish time": "Čas publikovaní",
    "Archived projects": "Archivované projekty",
    "Active projects": "Aktivní projekty",
    "respondents": "respondenti",
    "observers": "pozorovatelé",
    "moderators": "moderátoři",
    "respondent": "respondent",
    "observer": "pozorovatel",
    "moderator": "moderátor",
    "Role to notify": "Informovat roli",
    "Subject": "Předmět",
    "Notify users of ": "Informovat uživatele – ",
    "Manage access for ": "Spravovat přístup pro ",
    "project": "projekt",
    "Invite users via link (users not listed within any role will be created as respondents)": "Pozvat uživatele prostřednictvím odkazu (uživatelé, kteří nejsou uvedeni v žádné roli, budou vytvořeni jako respondenti)",
    "Copy link": "Kopírovat odkaz",
    "Link copied": "Odkaz zkopírován",
    "name: {:projectName} description: {:projectDescription}": "Název: {:projectName} popis: {:projectDescription}",
    "name: {:projectName}<br />description: {:projectDescription}": "Název: {:projectName}<br />Popis: {:projectDescription}",
    "Project invitation {:projectName}": "Pozvánka na projekt {:projectName}",
    "name: {:projectName} email: {:email} role: {:roleType}": "název: {:projectName} email: {:email} role: {:roleType}",
    "name: {:projectName}<br />invitation accepted by: {:email}<br />role: {:roleType}": "Název: {:projectName}<br />Pozvání přijato uživatelem: {:email}<br />role: {:roleType}",
    "Invitation accepted - {:projectName}": "Pozvánka přijata – {:projectName}",
    "Invitation accepted – {:projectName}": "Pozvánka přijata – {:projectName}",
    "Discussion is closed": "Diskuze je uzavřena.",
    "Profile updated by respondent": "Profil aktualizován respondentem",
    "for this project": "pro tento projekt",
    "updated": "aktualizován",
    "Profile updated by user {:profileEmail}": "Profil aktualizován uživatelem {:profileEmail}",
    "Page not found": "Požadovaná stránka neexistuje.",
    "Requested page does not exist": "Požadovaná stránka neexistuje.",
    "Internal Server Error": "Chyba serveru",
    "Sorry, something went wrong Please try again later": "Promiňte, něco se pokazilo. Prosím, zkuste to znovu později.",
    "Sorry, something went wrong. Please try again later.": "Promiňte, něco se pokazilo. Prosím, zkuste to znovu později.",
    "Home page": "Domovská stránka",
    "Unauthorised attempt to access": "Neoprávněný pokus o přístup",
    "Unauthorised": "Neoprávněný přístup",
    "as anonym": "jako anonym",
    "New message from user {:fromName} - {:message}": "Nová zpráva od {:fromName} – {:message}",
    "New message from user {:fromName} – {:message}": "Nová zpráva od {:fromName} – {:message}",
    "New message": "Nová zpráva",
    "See more": "Zobrazit více",
    "Discussion": "Diskuze",
    "Select conversation": "Vyberte konverzaci",
    "New reaction {:discussionName} has been added": "Přibyla nová reakce u diskuze {:discussionName}",
    "New reaction {:discussionName} has been added.": "Přibyla nová reakce u diskuze {:discussionName}.",
    "Your message {:messageText} in {:discussionName} has new reaction {:replyText}": "Vaše zpráva {:messageText} v diskuzi {:discussionName} má novou reakci {:replyText}",
    "Discussion {:discussionName} has been added": "Byla přidána diskuze {:discussionName}",
    "Discussion {:discussionName} has been added.": "Byla přidána diskuze {:discussionName}.",
    "Profile registration : {:email}": "Registrace profilu : {:email}",
    "Profile registration": "Registrace profilu",
    "Discussion {:discussionName} has been added to {:projectName}": "Nová diskuze {:discussionName} byla přidána k projektu {:projectName}",
    "Discussion {:discussionName} has been added to {:projectName}.": "Nová diskuze {:discussionName} byla přidána k projektu {:projectName}.",
    "Discussion {:discussionName} has been added to {:projectName} {:discussionIntroduction}": "Nová diskuze {:discussionName} byla přidána k projektu {:projectName}.<br/> {:discussionIntroduction}. <br/>",
    "Introduction": "Popis",
    "Generate exports": "Generovat export",
    "Download messages export": "Export diskuzí ke stažení",
    "Download users export": "Export uživatelů ke stažení",
    "Tags": "Štítky",
    "Reply to message": "Reagovat na zprávu",
    "No users to notify": "Žádní uživatelé nebudou informováni.",
    "Email badly formatted": "Email je ve špatném formátu",
    "Max length is {:maxLength} characters.": "Maximální délka je {:maxLength} znaků.",
    "Min length is {:minLength} characters.": "Minimální délka je {:minLength} znaků.",
    "Login failed" : "Neplatné přihlašovací údaje.",
    "role for this project has been updated": "role pro tento projekt byla aktualizována",
    "Roles for this project has been updated" : "Role pro tento projekt byly aktualizovány",
    "Only email invitation is allowed for this project" : "Pouze pozvánky prostřednictvím emailu jsou pro tento projekt povoleny",
    "You have no open projects": "Nemáte žádné otevřené projekty",
    "Disable discussion locking": "Vypnout uzamykaní jednotlivých diskuzí",
    "Discussion locking is disabled for this project": "Uzamykaní diskuzí je vypnuto pro tento projekt",
    "Moderator added message in {:discussionName}": "Moderátor přidal zprávu u diskuze {:discussionName}",
    "Moderator added message {:messageText} in {:discussionName}": "Moderátor přidal zprávu {:messageText} u diskuze {:discussionName}",
    "Moderator added message <p><strong>{:messageText}</strong></p> in {:discussionName}": "Moderátor přidal zprávu <p><strong>{:messageText}</strong></p> u diskuze {:discussionName}",
    "Ask moderator": "Oslovit moderátora",
    "Message has been sent": "Zpráva byla odeslána",
    "Support": "Podpora",
    "Help": "Nápověda",
    "Ask project moderator": "Oslovte moderátora projektu",
    "New support message from user {:fromName} - {:message}": "Nová zpráva od {:fromName} – {:message}",
    "New support message": "Nápověda - nová zpráva",
    "Stats": "Statistika",
    "Show unread only": "Zobrazit pouze nepřečtené",
    "Settings": "Nastavení",
    "No respondents found": "Nebyli nalezeni žádní respondenti",
    "Add respondents": "Přidat respondenty",
    "Project messages count": "Počet zpráv v projektu",
    "Reactions count": "Počet reakcí",
    "Last activity" : "Poslední aktivita",
    "No messages": "Žádné zprávy",
    "Most active respondents": "Nejaktivnější respondenti",
    "Project is not selected": "Není vybrán projekt",
    "Load user reactions": "Načíst reakce uživatele",
    "All discussions": "Všechny diskuze",
    "Filter by discussion": "Filtrovat dle diskuze",
    "User reactions": "Reakce uživatele",
    "All": "Všechny",
    "Respondents": "Respondenti",
    "Analytics of": "Analýza",
    "Analytics": "Analýza",
    "Filter by respondent": "Filtr podle respondenta",
    "Filter by tags": "Filtr podle štítků",
    "Other discussions": "Ostatní diskuze",
    "Cancel editing of message": "Zrušit editaci zprávy",
    "u can edit message only for minute from its creation" : "Zprávu můžete upravit pouze minutu od jejího vytvoření",
    "Message updated" : "Zpráva upravena",
    "Comment updated" : "Komentář upraven",
    "Published": "Publikováno",
    "Display name": "Uživatelské jméno",
    "Is respondent": "Je respondent",
    "Dislikes count": "Počet záporných hlasů",
    "Likes count": "Počet kladných hlasů",
    "Responses count": "Počet reakcí",
    "Total messages count": "Počet zpráv celkem",
    "message link": "odkaz na zprávu",
    "anonymous": "anonymní",
    "Direct responses count" : "Počet přímých reakcí",
    "Export data only related to the selected discussion": "Exportovat data pouze pro vybranou diskuzi",
    "Export whole project": "Exportovat celý projekt",
    "Project related chat": "Soukromá diskuse k projektu",
    "No items found": "Nebyly nalezeny data",
    "All comments": "Všechny komentáře",
    "Users and their properties": "Účastnosti a jejich vlastnosti",
    "Discussion description": "Popis diskuze",
    "Private message": "Soukromá zpráva",
    "Open message in discussion": "Otevřit zprávu v diskuzi",
    "No accepted invitations": "Nebyly nalezeny žádné akceptováné pozvánky",
    "Not yet accepted invitations": "Neakceptováné pozvánky",
    "Project is locked" : "Projekt je uzamčen",
    "Discussion is locked" : "Diskuze je uzamčena",
    "User profiles": "Uživatelské profily",
    "Discussion cannot be published as project has no respondent" : "Diskuzi nelze publikovat, protože projekt nemá přiřazené žádné respondenty",
    "Item has been removed" : "Položka byla odstraněna",
    "Show project information": "Zobrazit informace o projektu",
    "Hide project information": "Skrýt informace o projektu",
    "Select other conversation": "Vyberte jinou konverzace",
    "Accepted invitations": "Akceptováných pozvánek",
    "Unaccepted invitations": "Pozvánek bez registrace",
    "Total invitations": "Celkem pozvánek",
    "Select discussion": "Vyberte diskuzi",
    "Set to be published": "Datum zveřejnění",
    "Set to be locked": "Datum ukončení",
    "Message link has been copied": "Odkaz na zprávu zkopírován",
    "Message starred": "Zpráva označena hvězdičkou",
    "Message not starred": "Zpráva není označena hvězdičkou",
    "Only starred messages": "Jen zprávy označené hvězdičkou",
    "Star": "Hvězdička",
    "Add discussion" : "Přidat diskuzi",
};

export default messages;