import React from "react";
import { Route, Switch } from "react-router";

//containers
import NoMatch from "../containers/errors/NoMatch";
import UnauthorisedAccessPage from "../containers/errors/UnauthorisedAccessPage";
import ConnectionError from "../containers/errors/ConnectionError";
import Dashboard from "../containers/pages/Dashboard";
import ServerError from "../containers/errors/ServerError";

import EditProject from "../containers/pages/project/EditProject";
import ListProject from "../containers/pages/project/ListProject";
import ProjectDashboard from "../containers/pages/project/ProjectDashboard";
import EditDiscussion from "../containers/pages/discussion/EditDiscussion";
import Discussion from "../containers/pages/discussion/Discussion";
import EditProfile from "../containers/pages/profile/EditProfile";
import Chat from "../containers/pages/chat/Chat";
import EditProjectUsers from "../containers/pages/project/EditProjectUsers";
import Invitation from "../containers/pages/invitation/Invitation";
import ProjectPreview from "../containers/pages/project/ProjectPreview";
import CompanyDetail from "../containers/pages/company/CompanyDetail";
import ProjectNotify from "../containers/pages/project/ProjectNotify";
// import ExportDiscussion from "../containers/pages/discussion/ExportDiscussion";
import Support from "../containers/pages/support/Support";
import ProjectStats from "../containers/pages/project/ProjectStats";
import EmailsLog from "../containers/pages/log/Emails";
import NotificationsLog from "../containers/pages/log/Notifications";

const AppRoutes = (props) => {


  return (
    <Switch>

      <Route
        path="/"
        exact
        component={Dashboard} />


      <Route 
      exact 
      path="/dashboard/" 
      component={Dashboard} />



{/* <Route 
      exact 
      path="/admin/" 
      component={Admin} /> */}
      
     
      <Route exact path="/projects/create/" render={() => <EditProject actionType="create" />} />
        <Route exact path="/projects/edit/:projectId" render={() => <EditProject actionType="edit" />} />
        <Route exact path="/project/:projectId/users/" render={() => <EditProjectUsers />} />
        <Route exact path="/projects/" render={() => <ListProject isArchive={false} />} />
        <Route exact path="/projects/archive" render={() => <ListProject isArchive={true}  />} />
        <Route exact path="/project/:projectId" render={() => <ProjectDashboard />} />
        <Route exact path="/projects/preview/:projectId/" render={() => <ProjectPreview />} />
        <Route exact path="/project/:projectId/notify" render={() => <ProjectNotify/>} />
        <Route exact path="/project/:projectId/discussion/create" render={() => <EditDiscussion actionType="create"/>} />
        <Route exact path="/project/:projectId/stats/" render={() => <ProjectStats />} />
        <Route exact path="/project/:projectId/discussion/edit/:discussionId" render={() => <EditDiscussion actionType="edit"/>} />
        {/* <Route exact path="/project/:projectId/discussion/export/:discussionId" render={() => <ExportDiscussion />} /> */}
        {/* <Route exact path="/project/:projectId/discussion/stats/:discussionId" render={() => <DiscussionStats />} /> */}
        <Route exact path="/project/:projectId/discussion/:discussionId" render={() => <Discussion />} />


        <Route exact path="/profile/edit/:id?" render={() => <EditProfile actionType="edit" />} />
        {/* <Route exact path="/chat/:userToId?" render={() => <Chat />} /> */}
        <Route exact path="/chat/project/:projectId" render={() => <Chat />} />
        <Route exact path="/chat/project/:projectId/:conversationId" render={() => <Chat />} />
        <Route exact path="/chat/about-message/:userToId/:projectId/:discussionId/:messageId" render={() => <Chat />} />
        {/* <Route exact path="/chat/:userToId/project/:projectId" render={() => <Chat />} /> */}
        
        <Route exact path="/invitation/:invitationHash" render={() => <Invitation />} />

        <Route exact path="/company/" render={() => <CompanyDetail />} />
        <Route exact path="/support/:userToId?" render={() => <Support />} />
        <Route exact path="/mails-log/" render={() => <EmailsLog />} />
        <Route exact path="/notifications-log/" render={() => <NotificationsLog />} />





      

      <Route exact path="/connection-error" component={ConnectionError} />

      <Route exact path="/404" component={NoMatch} />
      <Route exact path="/500" component={ServerError} />
      <Route exact path="/401" component={UnauthorisedAccessPage} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default AppRoutes;
